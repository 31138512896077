import React, {useState, useEffect} from 'react';
import { Link, NavLink } from 'react-router-dom'
import MobileNav from './mobile-nav/mobile-nav'
import styled from 'styled-components';
import './navbar.scss'


const Container = styled.div`
  width: 100%;
  height: 90px;
  align-items: center;
  flex-direction: row;
  background-color: #023d69;
`
const Logo = styled.img`
  width: 236px;
  height: 83px
  `;

function Navbar({light}) {
  const [active, setActive] = useState('init');
  const [navbar, setNavbar] = useState(null)

  useEffect(() => {
    setNavbar(document.querySelector('.navbar'))
  }, [])

  if (navbar) {
    window.onscroll = function () {
      if (window.scrollY > 100) {
        navbar.classList.add('short');
      } else {
        navbar.classList.remove('short');
      }
    }
  }

  return (
    <React.Fragment>
      <nav className={`navbar navbar-expand-lg fixed-top ${!light ? 'bg-primary' : 'navbar-light bg-white'}`}>
        <Container className='container'>
          <Link className="navbar-brand" to="/">
            <Logo src='/assets/img/logos/untapped.png'></Logo>  
          </Link>
          <button className="navbar-toggler" type="button" onClick={() => setActive('active')}>
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/home" activeClassName="active">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about" activeClassName="active">About Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/team" activeClassName="active">Team</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/portfolio" activeClassName="active">Portfolio</NavLink>
              </li>
            </ul>

            <Link  className={`btn btn-primary py-2 ml-5 mt-5 ${!light ? 'btn-warning' : 'btn-primary'}` } 
                  style={{color:'#ffffff',}}
                  to="/contact">Contact</Link>
          </div>

        </Container>
      </nav>
      <MobileNav active={active} setActive={(value) => setActive(value)} />
    </React.Fragment>
  );
}

export default Navbar;
