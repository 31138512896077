import React from 'react'
import Navbar from '../../common/navbar/navbar'
import Footer from '../../common/footer/footer'
import CurveSection from '../../common/CurvedSection'
import TopChev from '../../../../src/assets/img/curveChev.svg'
import styled from 'styled-components';
import ServiceMol from '../../common/Service';


const TextTitle = styled.text`
  font-family: bebas-neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 7vw;
  align-self: center;
  display: flex;
  line-height: 5vw;
  letter-spacing: 0.045em;
  font-feature-settings: 'kern' off;  
  color: '#023d6a';
  z-index: 2;
  padding-top: 2vw;
`;

const Chev = styled.div`
background-image: url(${TopChev});
background-repeat: no-repeat; 
background-size: contain;
`


const TwoToneBackground = styled.div`
background: linear-gradient(#023D6A 15%, 15%, white 90%);
`

const TeamText = styled.div`
font-family: lato, sans-serif;;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
text-align: center;
letter-spacing: 0.03em;
color: #023D6A;
`

const BottomBar = styled.div`
width: 100%;
height: 0.5vw;
margin-top: 0vw;
margin-bottom: 5vw;
background: #FA7204;
border-radius: 0px;
align-self: center;
`;

const ServText = styled.div`
  width: 40%;
  color: rgba(2, 61, 106, 1);
  font-family: Montserrat, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.72px;
  margin-top: 2vw;
  align-self: 'center';
`
const serviceStyle = {
  height: '50%',
  color: '#023D6A',
  fontFamily: 'Bebas Neue',
  fontSize: '5vw',
  fontWeight: 600,  
  letterSpacing: 2.88,
  alignSelf: 'center',
  display: 'flex',
  align: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}

const start = (
  "Untapped began as a collective effort by a group of like-minded individuals to promote and grow the digital media industry in the Appalachian Highlands. Our mission is to connect talented artists with the businesses—small and large—that want to improve their visibility in the digital era."
);

const mission = (
"Untapped Collective exists to connect artists to clients, clients to patrons, and patrons to the Appalachian Highlands. Let’s create together."
);
const founders = (
"Untapped’s founders saw a large group of talented artists that were stuck choosing between moving away or pursuing an alternate career path. We connect these artists with businesses and individuals who need their services, with the goal of creating a thriving tech ecosystem in our region."
);
const tagLine = (
"By tapping into the multi-talented pool of artists in the area, we are able to provide a wide range of skill sets to tackle any project, while delivering high quality work at reasonable prices. "
);

const About = () => {

  return (
    <div style={{flex:1, display:'flex', backgroundColor:'#023D6A', flexDirection: 'column', paddingTop: 200}}>
      <Navbar/>
      <TwoToneBackground>
      <section className="whoBackground" style={{flex:1, display:'flex', backgroundColor:'transparent', zIndex:5,}}>
      <CurveSection color={'striped'}>
      <div style={{alignSelf:'center', display:'flex'}}>
        <div style={{flexDirection:'column', display:'flex', alignItems:'center', flexDirection: 'column'}}>
          <div  style={{alignSelf:'center', flex:1}}>
          <Chev style={{ width: '30vw', height: '3vh', alignSelf: 'center', alignContent:'center', marginTop: '4vw'}}></Chev>
          </div>
          <div style={{flexDirection:'column', display:'flex',  flexDirection: 'column',alignItems:'center', paddingTop: '0%',paddingBottom:'20%', paddingLeft:'0%', paddingRight: '0%'}}>
              <div style={{paddingLeft:'10%', display:'flex', flexDirection:'column', alignContent:'center', paddingRight:'10%'}}>
              <div style={serviceStyle}>
                <TextTitle style={{alignSelf:'center', textJustify:'center',flexDirection:'row', display: 'flex'}}>ABOUT UNTAPPED</TextTitle>
              </div>
              <TeamText style={{paddingTop:'6%'}}>{start}</TeamText>
              <TeamText style={{paddingBottom:'10%',paddingTop:'1%'}}>{founders}</TeamText>
              <BottomBar/>
              <div style={serviceStyle}>
                <TextTitle style={{alignSelf:'center', textJustify:'center',flexDirection:'row', display: 'flex'}}>OUR VISION</TextTitle>
              </div>
              <div style={{marginTop:'1vw', marginBottom: '3vw'}}>
              <TeamText style={{paddingBottom:'1%',paddingTop:'2%'}}>{tagLine}</TeamText> 
              <TeamText style={{paddingBottom:'10%',paddingTop:'2%'}}>{mission}</TeamText>
              </div>
              <BottomBar style={{marginBottom:'10vw'}}/>
              <div style={serviceStyle}>
                <TextTitle style={{alignSelf:'center', textJustify:'center',flexDirection:'row', display: 'flex'}}>SERVICES</TextTitle>
              </div> 
              <ServText style={{display:'flex', alignSelf:'center', marginBottom:'3%'}}>3D Animation, Technical Animation, Motion Graphics, Interactive Design, Website Design, Graphic Design.</ServText>
              </div>
            <ServiceMol/>
          </div>
          <div style={{alignItems:'center', display: 'flex', flexDirection: 'column'}}>
              
          </div>
        </div>
      </div>
      </CurveSection>
    </section>
      <Footer light/>
      </TwoToneBackground>
    </div>
)
}

export default About