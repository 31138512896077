import React from 'react'
import Navbar from '../../common/navbar/navbar'
import Footer from '../../common/footer/footer'
import CurveSection from '../../common/CurvedSection'
import TopChev from '../../../../src/assets/img/curveChev.svg'
import styled from 'styled-components';


const Chev = styled.div`
background-image: url(${TopChev});
background-repeat: no-repeat; 
background-size: contain;
`

const TextTitle = styled.text`
  font-family: bebas-neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 4vw;
  align-self: center;
  display: flex;
  line-height: 5vw;
  letter-spacing: 0.045em;
  font-feature-settings: 'kern' off;  
  color: '#023d6a';
  z-index: 2;
  padding-top: 2vw;
`;
const Short = styled.div`

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 1vwpx;
line-height: 20px;
text-align: center;
letter-spacing: 0.045em;
padding-left: 30%;
padding-right: 30%;

color: rgba(2, 61, 106, 0.7);

`

const TwoToneBackground = styled.div`
background: linear-gradient(#023D6A 15%, 15%, white 90%);
`

const PlayerContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; //16 x 9
  position: relative;
`

const Player = styled.iframe`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`
const TeamText = styled.div`
  width: 100%;
  color: #023D6A;
  font-family: Montserrat, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.72px;
  margin-top: 0.3vw;
  margin-bottom: 1vw;
`

const serviceStyle = {
  height: '50%',
  color: '#023D6A',
  fontFamily: 'bebas-neue, sans-serif;',
  fontSize: '2vw',
  fontWeight: 'normal',
  fontStyle: 'normal', 
  letterSpacing: 2.88,
  alignSelf: 'center',
  display: 'flex',
  align: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop:'-3%'
}
const Portfolio = () => {
  const opts = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 0,
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 1
    }
  };

  return (
    <div style={{flex:1, display:'flex', backgroundColor:'#023D6A', flexDirection: 'column', paddingTop: 200}}>
      <Navbar/>
      <TwoToneBackground>
      <section className="whoBackground" style={{flex:1, display:'flex', backgroundColor:'transparent', zIndex:5,}}>
      <CurveSection color={'striped'}>
      <div style={{alignSelf:'center', display:'flex'}}>
        <div style={{flexDirection:'column', display:'flex', alignItems:'center', flexDirection: 'column'}}>
          <div  style={{alignSelf:'center', flex:1}}>
          <Chev style={{ width: '30vw', height: '3vh', alignSelf: 'center', alignContent:'center', marginTop: '4vw'}}></Chev> 
          </div>
          <div style={{flexDirection:'column', display:'flex',alignItems:'center', paddingTop: '5%',paddingBottom:'30vw', paddingLeft:'0%', paddingRight: '0%'}}>
            <div style={serviceStyle}>
              <TextTitle style={{alignSelf:'center', textJustify:'center',flexDirection:'row', display: 'flex', marginTop:'-3vw'}}>VIEW OUR WORK</TextTitle>
            </div>
            <TeamText style={{paddingRight:'20%', paddingLeft: '20%'}} >A group of creative professionals dedicated to turning your visions into reality.</TeamText>
            <div style={{width:'100%', height:'100%'}}>
            <PlayerContainer style={{marginTop:'5%', borderColor:'orange', borderWidth:40}}>
              <Player style={{borderColor: '#F37423', borderWidth:'1vw'}} src="https://www.youtube.com/embed/3U3QTxt0Jmg" frameBorder="0"></Player>
            </PlayerContainer>
            </div>
          </div>
          <div style={{alignItems:'center', display: 'flex', flexDirection: 'column'}}>
              
          </div>
        </div>
      </div>
      </CurveSection>
    </section>
      <Footer light/>
      </TwoToneBackground>
    </div>
)
}

export default Portfolio