import React from 'react'
import styled from 'styled-components';
import AnimIcon from '../../assets/icons/animation.png'
import MotionIcon from '../../assets/icons/motion.png'
import InteractiveIcon from '../../assets/icons/interactive.png'
import GraphicIcon from '../../assets/icons/graphic.png'

// Style for "Services"
const ImageContainer = styled.img`
  width: 30%;
  height: 30%;
  object-fit: contain;
`
const Title = styled.div`
  color: #023d69;
  width: 100%;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: normal;
  margin-top: 1vw;
  letter-spacing: 0.81px;
  align-self: center;
`
const DivderBar = styled.div`
  width: 12vw;
  height: 0.2vw;
  background-color: #fa7204;
  margin-top: 1vw;
  margin-bottom: 1vw;
`
const Text = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  color: #023d6a;
  font-family: lato, sans-serif;;
  font-size: 1vw;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.72px;
`
const fillerText = (
    "Lorem ipsum dolor amet bitters four dollar toast kinfolk yuccie man braid bicycle rights. Prism freegan vice occupy trust fund. Keffiyeh kogi street art pitchfork vaporware, banh mi cardigan paleo"
)

const MotionGraphics = (
  "If you need something bold or unconventional to get the attention of social media users, our videos give scrollers something worth stopping for that looks and feels like nothing else around."
)
const Animation = (
  "Want to simplify a complex or complicated product, service or company story? Our animation team creates videos that are super effective tools for simplifying and clarifying information fast and to the point."
)
const Interactive = (
  "Do you have an idea for a digital product that will engage your clients? We can help you design an intuitive application that will engage, educate, and inspire. From interactive exhibits to mobile phone games, let’s make something awesome."
)
const Graphic = (
  "In the digital era, visual identity sets your work apart. From events to company logos, let our talented artists help you represent yourself digitally or in print. "
)

const BaseService = ( {image, title, text} ) =>(
<div style={{display:'flex', width:'100%', alignItems: 'center', flexDirection:'column', padding:'1%', }}>
    <ImageContainer src={image} alt=''></ImageContainer>
    <Title style={{textAlign:'center'}}>{title}</Title>
    <DivderBar style={{ zIndex:5}}/>
    <Text> {text} </Text>
</div>

)



const Service = () => {

  return (
    <div style={{display:'flex', flexDirection:'row', paddingTop:'5%'}}>
        <BaseService image={AnimIcon} title={"3D Animation"} text={Animation}/>
        <BaseService image={MotionIcon} title={"Motion Graphics"} text={MotionGraphics}/>
        <BaseService image={InteractiveIcon} title={"Interactive Design"} text={Interactive}/>
        <BaseService image={GraphicIcon} title={"Graphic Design"} text={Graphic}/>
    </div>
  )
}

export default Service
