import React, {useContext, useEffect} from 'react'
import Navbar from '../../common/navbar/navbar'
import Footer from '../../common/footer/footer'
import PeopleContext from '../../../context/people/people-context'
import CurveSection from '../../common/CurvedSection'
import TeamMember from '../../common/team-member/team-member'
import TopChev from '../../../../src/assets/img/curveChev.svg'
import styled from 'styled-components';

const Chev = styled.div`
background-image: url(${TopChev});
background-repeat: no-repeat; 
background-size: contain;
`

const TextTitle = styled.text`
  font-family: bebas-neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 4vw;
  align-self: center;
  display: flex;
  line-height: 5vw;
  letter-spacing: 0.045em;
  font-feature-settings: 'kern' off;  
  color: #023d6a;
  z-index: 2;
  padding-top: 2vw;
`;

const Short = styled.div`

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 1vwpx;
line-height: 20px;
text-align: center;
letter-spacing: 0.045em;

color: rgba(2, 61, 106, 0.7);

`
const TeamText = styled.div`
font-family: font-family: lato, sans-serif;;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
text-align: center;
letter-spacing: 0.03em;
color: #023D6A;
`
const TwoToneBackground = styled.div`
background: linear-gradient(#023D6A 15%, 15%, white 90%);
`

const serviceStyle = {
  height: '50%',
  color: '#023D6A',
  fontFamily: 'bebas-neue, sans-serif;',
  fontSize: '4vw',
  fontWeight: 600,  
  letterSpacing: 2.88,
  alignSelf: 'center',
  display: 'flex',
  align: 'center',
  justifyContent: 'center',
}
const Team = () => {
  const peopleContext = useContext(PeopleContext)

  useEffect(() => {
    peopleContext.getPeople()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {people, loading} = peopleContext

  const findPerson = (items, id) => {
    return items.find(item => item.id === id)
  }

  const Laurel = findPerson(people, 1)
  const benjamin = findPerson(people, 2)
  const robert = findPerson(people, 3)
  const john = findPerson(people, 4)
  const maheen = findPerson(people, 5)

  return (
    <div style={{flex:1, display:'flex', backgroundColor:'#023D6A', flexDirection: 'column', paddingTop: 200}}>
      <Navbar/>
      <TwoToneBackground>
      <section className="whoBackground" style={{flex:1, display:'flex', backgroundColor:'transparent', zIndex:5,}}>
      <CurveSection color={'striped'}>
      <div style={{alignSelf:'center', display:'flex'}}>
        <div style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
          <div  style={{alignSelf:'center', flex:1}}>
          <Chev style={{ width: '30vw', height: '3vh', alignSelf: 'center', alignContent:'center', marginTop: '4vw'}}></Chev> 
          </div>
          <div style={{flexDirection:'column', display:'flex', alignItems:'center', paddingLeft:'18%', paddingRight: '18%'}}>
            <div style={serviceStyle}>
              <TextTitle style={{alignSelf:'center', textJustify:'center',flexDirection:'row', display: 'flex'}}>MEET OUR TEAM</TextTitle>
            </div>
            <Short style={{paddingLeft:'20%', paddingRight: '20%', marginTop:'3%'}}>A group of creative professionals dedicated to turning your visions into reality.</Short>
          </div>
          <div style={{alignItems:'center', display: 'flex', flexDirection: 'column', }}>
              <div style={{display: 'flex', alignItems:'center',}}>
              {Laurel && <TeamMember person={Laurel}/>}
              {benjamin && <TeamMember person={benjamin}/>}
              </div>
              <div style={{display: 'flex', alignItems:'center'}}>
              {john && <TeamMember person={john}/>}
              {robert && <TeamMember person={robert}/>}
              {maheen && <TeamMember person={maheen}/>}
              </div>
          </div>
        </div>
      </div>
      </CurveSection>
    </section>
      <Footer light/>
      </TwoToneBackground>
    </div>
)
}

export default Team