import React from 'react'
import './team-member.scss'
import styled from 'styled-components';
import PortraitMask from '../../../assets/img/PortraitMask.svg';
import OrangeBorder from '../../../assets/img/OrangeBorder.svg'
const Clipimg = styled.img`
    z-index:30;
    display: flex;
    mask-image: url(${PortraitMask});
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-border-width: 1rem;
;`
const Border = styled.img`
    z-index:30;
    display: flex;
    mask-image: url(${PortraitMask});
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-border-width: 1rem;
;`

const Name = styled.div`
  width: 100%;
  color: #FFFFFF;
  font-family: Montserrat, sans-serif;
  font-size: 2vw;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  text-shadow: 2px 2px 5px  #000, -2px -2px 5px  #000, 2px -2px 5px  #000, -2px 2px 5px  #000;
  letter-spacing: 0.72px;
`
const PositionSpan = styled.span`
  width: 100%;
  color: #FFFFFF;
  font-family: Montserrat, sans-serif;
  font-size: 1.3vw;
  font-weight: 300;
  text-shadow: 2px 2px 5px  #000, -2px -2px 5px  #000, 2px -2px 5px  #000, -2px 2px 5px  #000;
  text-align: center;
  letter-spacing: 0.72px;
`

const TeamMember = ({person: {imgUrl, name, position}}) => {
  return (
      <div>
        <div  className="team-member">
          <Clipimg src={imgUrl} alt="" className="img-fluid" style={{width: '30vw', height: '30%' , zIndex: 2,}}/>
          <div style={{justifyContent: 'center', paddingTop: '90%'}}>
          <Name style={{marginBottom:10,}}>{name}</Name>
          <PositionSpan style={{paddingBottom:'10%'}}>{position}</PositionSpan>
          </div>
        </div>

      </div>
  )
}

export default TeamMember
