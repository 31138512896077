import React from 'react'
import Navbar from '../../common/navbar/navbar'
import Footer from '../../common/footer/footer'
import './contact.scss'
import ContactForm from '../../common/contact-form/contact-form'
import BlueCircle from '../../../assets/img/crcBlueStripes.png'
import WhiteCircle from '../../../assets/img/crcWhiteStripes.png'
import BarSVG from '../../../assets/img/ContactFormBar.svg'
import styled from 'styled-components';


const Short = styled.h5`
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 1vw;
line-height: 20px;
letter-spacing: 0.045em;
color: rgba(255, 255, 255, 0.7);
`

const TextTitle = styled.div`
  font-family: bebas-neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 4vw;
  align-self: center;
  display: flex;
  line-height: 5vw;
  letter-spacing: 0.045em;
  font-feature-settings: 'kern' off;  
  color: #FFFFFF;
  z-index: 2;
  padding-top: 2vw;
`;

const Bar = styled.div`
width:60px;
height: 600px;
background-image: url(${BarSVG});
background-repeat: no-repeat;
background-color: transparent;
background-size: contain;
margin-top: 17%;

`
const MainContainer = styled.div`
  background-image: url(${WhiteCircle}), url(${BlueCircle});
  background-size: 40%, 40%, 25%;
  background-repeat: no-repeat;
  background-position: 100% 70%, 90% 80%;
`


const Contact = () => {
  return (
    <React.Fragment>
      <Navbar />
      <MainContainer style={{zIndex:10, backgroundColor: '#023d6a', height: '100%', paddingTop: 200, paddingLeft: '20%', display: 'flex', flexDirection:'row', paddingBottom: '10%' }}>
              <Bar></Bar>
              <div className="col-lg-7">
                <TextTitle>HOW CAN WE HELP?</TextTitle>
                <Short className="mb-6">We answer all inquiries..</Short>
                <ContactForm/>
              </div>
      </MainContainer>
      <Footer short/>
    </React.Fragment>
  )
}

export default Contact