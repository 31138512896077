import React from 'react'
import BlueCircle from '../../../../assets/img/crcBlueStripes.png'
import { Link, NavLink } from 'react-router-dom'
import WhiteCircle from '../../../../assets/img/crcWhiteStripes.png'
import styled from 'styled-components';

const barStyle = {
  fontSize: 'Arial',
  backgroundColor: '#FA7204',
  width: '80%',
  height: 10,
  alignSelf: 'center',
};
const WorkButton = styled.button`
  width: 100%;
  height: 100%;
  border: 5px solid #fa7204;
  background-color: #023d6a;
  z-index: 2;
`
const ViewOurWork = styled.div`
  color: #ffffff;
  font-family:  bebas-neue, sans-serif;
  font-style: normal;
  font-size: 2.5vw;
  padding-left: 4vw;
  padding-right: 4vw;
`
const TextTitle = styled.text`
  font-family: bebas-neue, sans-serif;
  font-style: Regular;
  font-weight: normal;
  font-size: 4vw;
  align-self: center;
  display: flex;
  line-height: 5vw;
  letter-spacing: 0.045em;
  font-feature-settings: 'kern' off;  
  color: #FFFFFF;
  z-index: 2;
`;

const TextBar = styled.div`
width: 26%;
height: 0.5vw;
background: #FA7204;
border-radius: 0px;
align-self: center;
`;

const MainContainer = styled.div`
  background-image: url(${BlueCircle}), url(${WhiteCircle});
  background-size: 40%, 40%, 25%;
  background-repeat: no-repeat;
  background-position: -15% 80%, -5% 70%;
`

const HomeHeader = () => {
  return (
    <div>
      <MainContainer style={{zIndex:1, height: '100%', paddingTop: 200, display: 'flex', flexDirection:'column', paddingBottom: '10%' }}>
        <div style={{width: '100%', height:'50%', alignSelf: 'center', zIndex:3, display: 'flex', flexDirection:'column'}}>
            <TextBar style={{ marginBottom: '1%', marginTop: '1%'}}/>
            <div style={{  alignSelf:'center', width:'40%', height: '10%', alignItems: 'center', display: 'flex', flexDirection:'column'}}>
              <TextTitle style={{alignSelf: 'center'}}>WE ARE DESIGNING</TextTitle>
              <TextTitle>THE FUTURE OF TECHNOLOGY</TextTitle>
              <TextTitle>IN EAST TENNESSEE</TextTitle>
            </div>
            <TextBar style={{ marginBottom: '1%', marginTop: '1%',  }}/>
        </div>
        <div style={{alignSelf:'center', display: 'flex'}}>
          <WorkButton style={{alignSelf: 'center', marginTop: '30%', marginBottom: '30%',}}>
            <NavLink className="nav-link" to="/portfolio" >
              <ViewOurWork>VIEW OUR WORK</ViewOurWork>
            </NavLink>
          </WorkButton>
        </div>

      </MainContainer>

      </div>
  )
}

export default HomeHeader
