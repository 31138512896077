import React, {useContext, useEffect} from 'react'
import TeamMember from '../../../common/team-member/team-member'
import PeopleContext from '../../../../context/people/people-context'
import CurveSection from '../../../common/CurvedSection'
import TopChev from '../../../../assets/img/curveChev.svg'
import styled from 'styled-components';

const Chev = styled.div`
background-image: url(${TopChev});
background-repeat: no-repeat; 
background-size: contain;
`
const TeamText = styled.div`
  width: 70%;
  color: rgba(255, 255, 255, 0.7);
  font-family: Montserrat, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.72px;
  margin-top: 0.3vw;
  margin-bottom: 3vw;
`
const serviceStyle = {
  height: '50%',
  color: '#FFFFFF',
  fontFamily: 'bebas-neue, sans-serif',
  fontSize: '4vw',
  fontWeight: 'normal',  
  letterSpacing: 2.88,
  alignSelf: 'center',
  display: 'flex',
  align: 'center',
  justifyContent: 'center',
}
const TeamSection = () => {
  const peopleContext = useContext(PeopleContext)

  useEffect(() => {
    peopleContext.getPeople()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const findPerson = (items, id) => {
    return items.find(item => item.id === id)
  }

  const {people} = peopleContext

  const Laurel = findPerson(people, 1)
  const benjamin = findPerson(people, 2)
  const robert = findPerson(people, 3)
  const john = findPerson(people, 4)
  const maheen = findPerson(people, 5)
  return (
    <section className="whoBackground" style={{flex:1, display:'flex', backgroundColor:'transparent', zIndex:5,marginTop: '-15%'}}>
      <CurveSection color={'blue'}>
      <div style={{alignSelf:'center', display:'flex'}}>
        <div style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
          <div  style={{alignSelf:'center', flex:1}}>
          <Chev style={{ width: '30vw', height: '3vh', alignSelf: 'center', alignContent:'center', marginTop: '4vw'}}></Chev>
          </div>
          <div style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
            <div style={serviceStyle}>
              <div style={{alignSelf:'center', textJustify:'center',flexDirection:'row', display: 'flex', marginTop: -30}}>MEET OUR TEAM</div>
            </div>
            <TeamText>A group of creative professionals dedicated to turning your visions into reality.</TeamText>
          </div>
          <div style={{alignItems:'center', display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', alignItems:'center',}}>
              {Laurel && <TeamMember person={Laurel}/>}
              {benjamin && <TeamMember person={benjamin}/>}
              </div>
              <div style={{display: 'flex', alignItems:'center'}}>
              {john && <TeamMember person={john}/>}
              {robert && <TeamMember person={robert}/>}
              {maheen && <TeamMember person={maheen}/>}
              </div>
          </div>
        </div>
      </div>
      </CurveSection>
    </section>
  )
}

export default TeamSection
