import React, {useEffect} from 'react';
import HomeHeader from './home-header/home-header'
import Services from './services-section/services';
import TestimonialsState from '../../../context/testimonials/testimonials-state'
import styled from 'styled-components';
import * as Rellax from 'rellax/rellax'
import './home.scss'
import TeamSection from './team-section/team-section'
import Footer from '../../common/footer/footer'
import Navbar from '../../common/navbar/navbar'


const TwoToneBackground = styled.div`
background: linear-gradient(white 15%, 15%, #023D6A 90%);
`

const BlueWhite = styled.div`
background: linear-gradient( transparent 10%, 10%, #023D6A 20%, 20%, white 90%);
`


const Home = () => {
  useEffect(() => {
    window.rellax = new Rellax('.rellax')
  }, [])
  return (
    <TestimonialsState>
      <div style={{backgroundColor: 'transparent', display:'flex', flexDirection: 'column'}}>
      <Navbar />
      <div className="home">
        <div style={{zIndex:2}}>
          <HomeHeader/>
        </div>
        <div style={{zIndex:1,}}>
          <BlueWhite>
          <Services />
          </BlueWhite>
          <TwoToneBackground>
            <TeamSection />
          </TwoToneBackground>
        </div>
      </div>
      <Footer />
      </div>
    </TestimonialsState>
  );
};

export default Home;


/*


<app-navbar [navbarColor]="'dark'"></app-navbar>

<div className="home">




</div>

<app-footer></app-footer>


*/
