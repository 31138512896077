import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import PeopleState from '../context/people/people-state'
import BlogState from '../context/blog/blog-state'

import Home from './pages/home/home'
import About from './pages/about/about'
import NoMatch from './common/no-match/no-match'
import Team from './pages/team/team'
import Contact from './pages/contact/contact'
import Portfolio from './pages/portfolio/portfolio'

import '../assets/styles/main.scss'
import ScrollTop from './common/scroll-top/scroll-top'


const App = () => {
  return (
    <PeopleState>
      <BlogState>
        <Router>
          <ScrollTop>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/home" component={Home}/>
              <Route path="/team" component={Team}/>
              <Route path="/portfolio" component={Portfolio}/>
              <Route path="/about" component={About}/>
              <Route path="/contact" component={Contact}/>
              <Route component={NoMatch}/>
            </Switch>
          </ScrollTop>
        </Router>
      </BlogState>
    </PeopleState>
  )
}

export default App
