import React from 'react'
import CurveSection from '../../../common/CurvedSection'
import DownChev from '../../../../assets/img/downArrow.svg'
import ServiceMol from '../../../common/Service';

import styled from 'styled-components';
// Style for "Services"
const serviceStyle = {
  height: '30%',
  width: '100%',
  color: '#023d6a',
  fontFamily: 'bebas-neue, sans-serif',
  fontSize: '4vw',
  fontWeight: 'normal',  
  letterSpacing: 2.88,
  alignSelf: 'center',
  marginTop: '4vw',
  display: 'flex',
  align: 'center',
  justifyContent: 'center',
}




const ServText = styled.div`
  width: 40%;
  color: rgba(2, 61, 106, 1);
  font-family: Montserrat, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.72px;
  margin-top: 18px;
`

const Services = () => {
  return (
    <section className="motivation" style={{flex:1, display:'flex', backgroundColor: 'transparent', marginTop: '-8%',paddingBottom: '20%',}}>
        <CurveSection>
          <div style={{alignSelf:'center', display:'flex', alignItems:'center'}}>
            <div style={{flexDirection:'column', width:'100%', display:'flex', alignItems:'center', marginBottom: '10%'}}>
              <img src={DownChev} style={{marginTop:'3%', width:'5%', height: '10%'}} alt=''></img>
              <div style={serviceStyle}>
                <div style={{alignSelf:'center', textJustify:'center',flexDirection:'row', display: 'flex'}}>SERVICES</div>
              </div>
              <ServText>3D Animation, Technical Animation, Motion Graphics, Interactive Design, Website Design, Graphic Design.</ServText>
              <ServiceMol/>
            </div>

          </div>
        </CurveSection>
    </section>
  )
}

export default Services
