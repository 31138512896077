import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './footer.scss'

import {faInstagram, faFacebook, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faBehance} from '@fortawesome/free-brands-svg-icons';
import {faDribbble} from '@fortawesome/free-brands-svg-icons';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

const Footer = ({light, short}) => {
  const ContactText = styled.div`
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8vw;
    line-height: 22px;
    text-align: right;
    padding-top: 1vw;
    letter-spacing: 0.045em;
    color: ${light ? '#023D6A ' : '#FFFFFF'};

    `
    
    
    const LeftText = styled.div`
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1vw;
    line-height: 22px;
    text-align: left;
    padding-top: 1vw;
    letter-spacing: 0.045em;
    color: ${light ? '#023D6A ' : '#FFFFFF'};
    `

    const TopBar = styled.div`
    width: 100%;
    height: 0.2vw;
    background: ${light ? '#023D6A ' : '#FFFFFF'};
    border-radius: 0px;
    align-self: center;
    `;
    const BottomBar = styled.div`
    width: 100%;
    height: 1vw;
    background: #FA7204;
    border-radius: 0px;
    align-self: center;
    `;
    const Title = styled.div`
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3vw;;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.045em;
    color: #FA7204;
    `;

    const CopyRight = styled.div `
    font-family: font-family: lato, sans-serif;;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.045em;
    color: ${light ? '#023D6A ' : '#FFFFFF'};

    `
    
  return (
    <footer style={{backgroundColor: light ? 'white ' : '#023D6A', display:'flex', alignItems:'center', flexDirection:'column', paddingTop: '5%'}}>
      
      <div className="container" style={{flexDirection:'row'}}>
        <TopBar/>
        <div style={{backgroundColor: light ? 'white ' : '#023D6A', justifyContent:'space-between',display:'flex', flexDirection:'row', marginTop:'1%', marginBottom: '1%'}}>
          <div style={{ display:'flex', flexDirection:'column', alignContent:'space-between'}}>
            <NavLink to="/home" >
              <LeftText style={{paddingTop:'0'}}>Home</LeftText>
            </NavLink>
            <NavLink to="/about" >
              <LeftText>About Us</LeftText>
            </NavLink>
            <NavLink to="/team" >
              <LeftText>Team</LeftText>
            </NavLink>
            <NavLink to="/portfolio" >
              <LeftText>Portfolio</LeftText>
            </NavLink>
            <NavLink to="/contact" >
              <LeftText>Contact Us</LeftText>
            </NavLink>
          </div>

          <div style={{display:'flex', flexDirection: 'column', alignSelf:'flex-start'}}>
            <Title>All Business Inquiries</Title>
            <ContactText>Laurel Cook-Siffring</ContactText>
            <ContactText>Laurel@untappedcollective.com</ContactText>
            <ContactText>423 - 483 - 4029</ContactText>
            <div style={{display:'flex', flexDirection: 'row-reverse', marginTop:'1vw'}}>
                <a className={`mr-0 ${!light && 'text-white'}`} href="https://www.twitter.com/UntappedCo" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} style={{fontSize:'2vw', marginRight:'0vw'}}/>
                </a>
              <a className={`mr-0 ${!light && 'text-white'}`} href="https://www.instagram.com/untappedcollective/" target="_blank">
                <FontAwesomeIcon icon={faInstagram} style={{fontSize:'2vw', marginRight:'3vw'}}/>
              </a>
              <a className={`mr-0 ${!light && 'text-white'}`} href="https://www.facebook.com/untappedcollective/" target="_blank">
                <FontAwesomeIcon icon={faFacebook} style={{fontSize:'2vw', marginRight:'3vw'}}/>
              </a>
            </div>
          </div>
          
        </div>
        <BottomBar></BottomBar>
      </div>
      <CopyRight style={{color:'white', marginTop:'2%', marginBottom:'2%'}}>© Copyright 2019 Untapped Collective </CopyRight>
    </footer>
  )
}

export default Footer
