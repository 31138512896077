import React from 'react'
import WhiteArc from '../../assets/img/whiteArc.svg'
import Combined from '../../assets/img/combinedBlue.svg'
import Center from '../../assets/img/stripePattern.svg';
import TopLeft from '../../assets/img/leftStripeTop.svg'
import TopRight from '../../assets/img/rightStripeTop.svg'
import StripeWhite from '../../assets/img/StripedWhite.svg'
import styled from 'styled-components';
const DivContainer = styled.div`
  display: flex;
  width: 100%;
  flex:1;
  flex-direction: column;
`
const White = styled.div`
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
background-image: url(${WhiteArc});
background-repeat: no-repeat;
background-color: transparent;
background-size: 100%;
`
const Striped = styled.div`
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
background-image: url(${StripeWhite});
background-repeat: no-repeat;
background-color: transparent;
background-size: 100%;
`
const Blue = styled.div`
position: absolute;
display: flex;
left: 0;
right: 0;
top: 0;
bottom: 0;
background-image: url(${Combined});
background-repeat: no-repeat;
background-color: transparent;
background-size: 100%;

`

const BlueSection = (
  <div>
    <Blue style={{zIndex:4}}/>
</div>
)

const WhiteSection = (
  <div>
    <White style={{zIndex:4}}/>
</div>
)
const WhiteStriped = (
  <div>
    <Striped style={{zIndex:4}}/>
</div>
)


const CurvedSection = ({children, color}) => {

  let SvgComponent = null;
  switch (color) {
    case 'blue':
      SvgComponent = BlueSection;
      break;
    case 'striped': 
      SvgComponent = WhiteStriped;
      break;
    default: 
      SvgComponent = WhiteSection;
      break;
  }

  return (
    <section style={{flex:1, display:'flex', backgroundColor:'transparent'}} >
    <DivContainer > 
      <div style={{zIndex:5, height:'100%',minHeight: 0, alignSelf:'center',display:'flex', paddingRight: '20%', paddingLeft: '20%', backgroundColor: 'transparent'}}>
        {children}
      </div>
      {SvgComponent}
    </DivContainer>
    </section>
  )
}

export default CurvedSection
